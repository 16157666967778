import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled, { withTheme } from "styled-components";
import Web3 from "web3";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import { Panel, PanelGroup } from "rsuite";
import { Notification, toaster } from "rsuite";
import { Loader } from "rsuite";
import { Badge } from "rsuite";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { BrowserView, MobileView } from "react-device-detect";
import { config } from "chai";
import { Parallax } from "react-parallax";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-family: "coder";
  padding: 10px;
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  color: white;
  width: 320px;
  height: 60px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    transition: transform 0.5s;
    background-color: var(--secondary-hover);
    color: white;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;

export const CTNButton = styled.button`
  font-family: "coder";
  padding: 10px;
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  color: white;
  width: 320px;
  height: 60px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    transition: transform 0.5s;
    background-color: var(--secondary-hover);
    color: white;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;

export const Maxbtn = styled.button`
  font-family: "coder";
  font-size: 0.75rem;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-weight: bold;
  color: #6b6b6b;
  background-color: #f5f5f5;
  width: 100px;
  height: 30px;
  cursor: pointer;
  :hover {
    border-color: var(--secondary);
    transition: transform 0.5s;
    color: var(--secondary);
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--accent);
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    transform: scale(1.2);
    transition: transform 0.5s;
    color: silver;
  }
`;

export const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10%;
  width: 160px;
`;

export const LogoDivMobile = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  align-content: left;
  gap: 10%;
  margin-left: 5%;
  width: 40px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: center;
  margin: auto;
  width: 70%;
  border-radius: 4px;
  background-color: #f5f5f5;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapperHeader = styled.div`
  z-index: 1;
  position: fixed;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 150px;
  padding: 10px;
  background-color: #fff;
  @media (min-width: 767px) {
    flex-direction: row;
    max-height: 400px;
  }
  @media (max-width: 565px) {
    max-height: 330px;
  }
`;

export const ResponsiveWrapperHeaderMobile = styled.div`
  z-index: 1;
  position: fixed;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  background-color: #fff;
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 80px;
  @media (max-width: 767px) {
    width: 60px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 450px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 450px;
  }
  @media (min-width: 1000px) {
    width: 450px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;

export const PreviewImage = styled.img`
  width: 250px;
  border-radius: 5px;
  @media (min-width: 650px) {
    width: 150px;
  }
  @media (min-width: 800px) {
    width: 200px;
  }
  @media (min-width: 1000px) {
    width: 250px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;

export const HeroImg = styled.img`
  width: 700px;
  @media (min-width: 900px) {
    width: 7000px;
  }
  @media (min-width: 1000px) {
    width: 700px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 240px;
  }
`;

export const Styledroad = styled.img`
  width: 100%;
  border-radius: 5px;
  transition: width 0.5s;
`;

export const StyledImgSmall = styled.img`
  width: 220px;
  height: 220px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 220px;
    height: 220px;
  }
  @media (min-width: 1000px) {
    width: 220px;
    height: 220px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const WalletBox = styled.div`
  margin-left: 5%;
  text-decoration: none;
  color: #1e1d24;
  border-radius: 4px;
  background-color: #F5F5F5;
  font-weight: bold;
  font-size: 0.9em;
  width: 180px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 565px) {
    margin-right: 30%;
    height: 40px;
    margin-top: 10px;
    font-size: 1em;
  
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Not Connected");
  const [claimingNft, setClaimingNft] = useState(false);
  const [minted, setMinted] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [tokens, settokens] = useState(1);
  const [brd, setbrd] = useState("2px solid #FFFFFF");
  const [bxsh, setbxsh] = useState("0px 0px 3px 0px #FFFFFF");
  const [DOT, setDOT] = useState("red");
  const [type, setType] = React.useState("info");
  const [placement, setPlacement] = React.useState("topStart");
  const errmessage = (
    <Notification type={"error"} header={"Error"} closable>
      Sorry. Something is wrong, please try again later.
    </Notification>
  );
  const txmessage = (
    <Notification type={"success"} header={"Success!"} closable>
      Your MARITIME just minted!
    </Notification>
  );
  const mntmessage = (
    <Notification type={"info"} header={"In Progress"} closable>
      <Loader /> Minting your MARITIME...
    </Notification>
  );
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    DISPLAY_COST: 0,
    WL_Display: 0,
    GAS_LIMIT: 0,
    MAX_PER_TX: 0,
    MARKETPLACE: "",
    Telegram: "",
    Twitter: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = data.cost * tokens;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    console.log("Cost: ", price);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    setbrd("2px solid yellow");
    setbxsh("0px 0px 3px 0px yellow");
    toaster.push(mntmessage, { placement });
    blockchain.smartContract.methods
      .mint(tokens)
      .send({
        gasLimit: null,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry. Something is wrong, please try again later.");
        setClaimingNft(false);
        setMinted(false);
        toaster.push(errmessage, { placement });
        setbrd("2px solid red");
        setbxsh("0px 0px 3px 0px red");
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          "Congrats, successfully minted! Check NFTKey, Rareboard or TofuNFT to see your NFT"
        );
        toaster.push(txmessage, { placement });
        setbrd("2px solid green");
        setbxsh("0px 0px 3px 0px green");
        setMinted(true);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementtokens = () => {
    let newtokens = tokens - 1;
    if (newtokens < 1) {
      newtokens = 1;
    }
    settokens(newtokens);
  };

  const incrementtokens = () => {
    let newtokens = tokens + 1;
    if (newtokens > CONFIG.MAX_PER_TX) {
      newtokens = CONFIG.MAX_PER_TX;
    }
    settokens(newtokens);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(
        blockchain.account.substring(0, 4) +
          "..." +
          blockchain.account.substring(38, 42)
      );
      setDOT("green");
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        // ai={"center"}
        style={{ backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <BrowserView>
          <ResponsiveWrapperHeader>
            <LogoDiv>
              <AnchorLink href="#top" offset="80">
                <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
              </AnchorLink>
            </LogoDiv>

            <s.Headerlinks>
              <AnchorLink href="#mint" offset="80">
                <s.StyledLink>Mint</s.StyledLink>
              </AnchorLink>
              <AnchorLink href="#about" offset="80">
                <s.StyledLink>About</s.StyledLink>
              </AnchorLink>
              <AnchorLink href="#art" offset="80">
                <s.StyledLink>Art</s.StyledLink>
              </AnchorLink>
              <AnchorLink href="#fund" offset="80">
                <s.StyledLink>Fund</s.StyledLink>
              </AnchorLink>
              <AnchorLink href="#roadmap" offset="80">
                <s.StyledLink>Roadmap</s.StyledLink>
              </AnchorLink>
              <AnchorLink href="#team" offset="80">
                <s.StyledLink>Team</s.StyledLink>
              </AnchorLink>
              <AnchorLink href="#faq" offset="80">
                <s.StyledLink>FAQ</s.StyledLink>
              </AnchorLink>
              <s.StyledLink href="https://docs.bscmaritime.io/" target="_blank">
                🔗 Docs
              </s.StyledLink>
            </s.Headerlinks>

            <s.HeaderDiv>
              <s.socialDiv>
                <a href={CONFIG.MARKETPLACE_LINK} target={"_blank"}>
                  <s.Icons src="/config/images/nftkey.svg" alt="nftkey" />
                </a>
                <a href={CONFIG.Rareboard} target={"_blank"}>
                  <s.Icons src="/config/images/rareboard.svg" alt="rareboard" />
                </a>
                <a href={CONFIG.Telegram} target={"_blank"}>
                  <s.Icons src="/config/images/telegram.svg" alt="telegram" />
                </a>
                <a href={CONFIG.Twitter} target={"_blank"}>
                  <s.Icons src="/config/images/twitter.svg" alt="twitter" />
                </a>
                <a href={CONFIG.Discord} target={"_blank"}>
                  <s.Icons src="/config/images/discord.svg" alt="discord" />
                </a>
                <a href={CONFIG.SCAN_LINK} target={"_blank"}>
                  <s.Icons src="/config/images/bscscan.svg" alt="bscscan" />
                </a>
              </s.socialDiv>
              <WalletBox
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                {blockchain.account !== "" ? (
                  <>
                    <Badge color={DOT} />
                    &emsp;{walletAddress}
                  </>
                ) : null}
              </WalletBox>
            </s.HeaderDiv>
          </ResponsiveWrapperHeader>
        </BrowserView>
        <MobileView>
          <ResponsiveWrapperHeaderMobile>
            <LogoDivMobile>
              <a href="#" target={"_blank"}>
                <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
              </a>
            </LogoDivMobile>

            <s.HeaderDiv>
              <WalletBox
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                {blockchain.account !== "" ? (
                  <>
                    <Badge color={DOT} />
                    &emsp;{walletAddress}
                  </>
                ) : null}
              </WalletBox>
            </s.HeaderDiv>
          </ResponsiveWrapperHeaderMobile>
        </MobileView>
        <s.HeroContainer id="top">
          <Parallax
            bgImage="/images/hero-bg.jpg"
            bgImageAlt="Background 1"
            strength={220}
          >
            <s.HeroRow>
              <s.HeroColumn>
                <s.TextHeader>
                  Get ready to dive deeper on the BNB Smart Chain
                </s.TextHeader>
                <s.SpacerMedium />
                <s.TextCaption>
                  Join Smart Chain Maritime Society now!
                </s.TextCaption>
                <s.SpacerLarge />
                <AnchorLink href="#mint" offset="100">
                  <CTNButton>Mint MARITIME</CTNButton>
                </AnchorLink>
              </s.HeroColumn>
              <s.HeroColumnImage>
                <BrowserView>
                  <HeroImg src={"/images/shark-bg.png"} alt="image" />
                </BrowserView>
                <MobileView>
                  <HeroImg src={"/images/shark-bg-mobile.png"} alt="image" />
                </MobileView>
              </s.HeroColumnImage>
            </s.HeroRow>
          </Parallax>
        </s.HeroContainer>
        <s.SpacerMedium />
        <s.Container flex={1} jc={"center"} ai={"center"} id="mint">
          <s.SpacerLarge />
          <s.TextTitle>Smart Chain Maritime Society</s.TextTitle>
          <s.SpacerSmall />
          <s.TextP>Mint your MARITIME NFT to join the Society</s.TextP>
          <s.SpacerMedium />
        </s.Container>

        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <StyledImg src={"/config/images/preview.gif"} alt="image" />
          <s.SpacerSmall />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextSub
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "coder",
                    letterSpacing: "1px",
                  }}
                >
                  That's all, folks!
                </s.TextSub>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "coder",
                    letterSpacing: "2px",
                    lineHeight: "1.2",
                  }}
                >
                  Minted out, but you can buy on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink
                  target={"_blank"}
                  href={CONFIG.MARKETPLACE_LINK}
                  style={{ fontSize: "50px" }}
                >
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextSub
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontFamily: "coder",
                  }}
                >
                  {data.totalSupply} | {CONFIG.MAX_SUPPLY}
                </s.TextSub>
                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <>
                    <s.TextP>
                      Connect your wallet to be able to mint and check current
                      supply.
                    </s.TextP>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"}>
                      <s.SpacerSmall />
                      <CTNButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        Connect Wallet
                      </CTNButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                              fontFamily: "coder",
                              fontSize: 20,
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  </>
                ) : (
                  <>
                    {data.allowlistSale == true ? (
                      <>
                        {Number(data.allowlistSpots) > 0 ? (
                          <>
                            <s.AmountContainer
                              style={{
                                border: brd,
                                boxShadow: bxsh,
                              }}
                            >
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementtokens();
                                }}
                              >
                                -
                              </StyledRoundButton>
                              <s.TEXTamount>
                                &ensp;&ensp;&ensp;&ensp;{tokens}
                                &ensp;&ensp;&ensp;
                              </s.TEXTamount>
                              <s.SpacerMedium />
                              <StyledRoundButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementtokens();
                                }}
                              >
                                +
                              </StyledRoundButton>
                            </s.AmountContainer>
                            <s.SpacerSmall />
                            <s.TextSubTitle style={{ fontSize: 14 }}>
                              You can mint {data.allowlistSpots} NFT during
                              pre-sale
                            </s.TextSubTitle>
                            <s.SpacerSmall />
                            <Maxbtn
                              onClick={(e) => {
                                e.preventDefault();
                                settokens(data.allowlistSpots);
                              }}
                            >
                              Set Max
                            </Maxbtn>
                            <s.SpacerSmall />
                            <s.SpacerSmall />
                            <s.TextTotal
                              style={{
                                borderRadius: 10,
                                padding: 8,
                                color: "var(--accent)",
                                backgroundColor: "var(--primary)",
                              }}
                            >
                              &emsp;Total&emsp;&emsp;&emsp;&emsp;&emsp;
                              {(data.cost * tokens).toString().substring(0, 6)}{" "}
                              BNB&emsp;
                            </s.TextTotal>
                            <s.SpacerSmall />
                            <s.SpacerXSmall />
                            <s.Container
                              ai={"center"}
                              jc={"center"}
                              fd={"column"}
                            >
                              {data.paused == false ? (
                                <>
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTs();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? (
                                      <Loader
                                        speed="fast"
                                        content="Minting..."
                                      />
                                    ) : (
                                      "MINT"
                                    )}
                                  </StyledButton>
                                </>
                              ) : (
                                <>
                                  <StyledButton
                                    style={{ backgroundColor: "#050914" }}
                                  >
                                    Pre-sale starts 2nd September 4 PM UTC
                                  </StyledButton>
                                </>
                              )}
                            </s.Container>
                            <s.SpacerSmall />
                            <s.TextSubTitle style={{ fontSize: 14 }}>
                              Max {CONFIG.MAX_PER_TX} Per Tx
                            </s.TextSubTitle>
                            <s.SpacerXSmall />
                            <s.TextSubTitle
                              style={{ textAlign: "center", fontSize: "1rem" }}
                            >
                              {feedback}
                            </s.TextSubTitle>
                          </>
                        ) : (
                          <>
                            <s.TextTitle
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                                fontFamily: "coder",
                              }}
                            >
                              Sorry no Whitelist Spots
                            </s.TextTitle>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                                fontFamily: "coder",
                                lineHeight: "1.2",
                                fontSize: "20px",
                              }}
                            >
                              Please wait till Public Sale starts.
                            </s.TextDescription>
                            <s.SpacerSmall />
                            <s.TextTitle
                              style={{
                                textAlign: "center",
                                color: "var(--secondary)",
                                fontFamily: "coder",
                              }}
                            >
                              3rd September 4 PM UTC
                            </s.TextTitle>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <s.AmountContainer
                          style={{
                            border: brd,
                            boxShadow: bxsh,
                          }}
                        >
                          <StyledRoundButton
                            style={{ lineHeight: 0.4 }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementtokens();
                            }}
                          >
                            -
                          </StyledRoundButton>
                          <s.TEXTamount>
                            &ensp;&ensp;&ensp;&ensp;{tokens}&ensp;&ensp;&ensp;
                          </s.TEXTamount>
                          <s.SpacerMedium />
                          <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementtokens();
                            }}
                          >
                            +
                          </StyledRoundButton>
                        </s.AmountContainer>
                        <s.SpacerSmall />
                        <Maxbtn
                          onClick={(e) => {
                            e.preventDefault();
                            settokens(CONFIG.MAX_PER_TX);
                          }}
                        >
                          Set Max
                        </Maxbtn>
                        <s.SpacerSmall />
                        <s.SpacerSmall />
                        <s.TextTotal
                          style={{
                            borderRadius: 10,
                            padding: 8,
                            color: "var(--accent)",
                            backgroundColor: "var(--primary)",
                          }}
                        >
                          &emsp;Total&emsp;&emsp;&emsp;&emsp;&emsp;
                          {(data.cost * tokens).toString().substring(0, 6)}{" "}
                          BNB&emsp;
                        </s.TextTotal>
                        <s.SpacerSmall />
                        <s.SpacerXSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"column"}>
                          {data.paused == false ? (
                            <>
                              <StyledButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              >
                                {claimingNft ? (
                                  <Loader speed="fast" content="Minting..." />
                                ) : (
                                  "MINT"
                                )}
                              </StyledButton>
                            </>
                          ) : (
                            <>
                              <StyledButton
                                style={{ backgroundColor: "#050914" }}
                              >
                                Mint is not started yet
                              </StyledButton>
                            </>
                          )}
                        </s.Container>
                        <s.SpacerSmall />
                        <s.TextSubTitle style={{ fontSize: 14 }}>
                          Max {CONFIG.MAX_PER_TX} Per Tx
                        </s.TextSubTitle>
                        <s.SpacerXSmall />
                        <s.TextSubTitle
                          style={{ textAlign: "center", fontSize: "1rem" }}
                        >
                          {feedback}
                        </s.TextSubTitle>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>

        <s.SpacerLarge />
        <s.SectionContainer id="about">
          <Parallax
            bgImage="/images/bg-2.jpg"
            bgImageAlt="Background 2"
            strength={220}
          >
            <s.SectionRow>
              <s.SectionColumn>
                <s.TextTitle style={{ color: "var(--primary)" }}>
                  About Maritime
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextP style={{ color: "var(--primary)", opacity: "0.8" }}>
                  Smart Chain Maritime Society is a community of people who
                  share a passion for NFTs and the ocean. Our community offers
                  the following benefits:
                </s.TextP>
                <s.SpacerMedium />
                <s.TextP style={{ color: "var(--primary)" }}>
                  <ol style={{ listStyle: "disc", color: "var(--primary)" }}>
                    <li>NFT draws every Monday, Wednesday, and Friday.</li>
                    <li>Mariners Fund to benefit the community.</li>
                    <li>Regular raffles and competitions.</li>
                  </ol>
                </s.TextP>
                <s.TextP style={{ color: "var(--primary)", opacity: "0.8" }}>
                  We strive to create a fun and engaging community that
                  celebrates the beauty of the ocean and the limitless potential
                  of NFTs.
                </s.TextP>
              </s.SectionColumn>
            </s.SectionRow>
          </Parallax>
        </s.SectionContainer>

        <s.SectionContainer id="art">
          <s.SectionRow>
            <s.SectionColumn>
              <s.TextTitle>Art</s.TextTitle>
              <s.SpacerSmall />
              <s.TextP style={{ opacity: "0.8" }}>
                A collection of 4,960 Hand-drawn NFT’s based on how analysers
                compare Bitcoin network entities (BTC Holders) according to
                their holdings and divide them into eight separate groups, each
                titled after marine species;{" "}
                <b>
                  Shrimp, Crab, Octopus, Fish, Dolphin, Shark, Whale and
                  Humpback
                </b>
              </s.TextP>
              <s.SpacerMedium />
              <s.TextP>
                Unique trait categories consisting of Background, Species,
                Skins, Eyes, Mouths, Body and Head Props.
                <br />
                There are 12 unique drawings in the collection, with only 6
                Humpbacks, these are all 1:1 unique designs, There are also 6
                custom mythological creatures.
              </s.TextP>
              <s.SpacerMedium />
              <s.SectionRow>
                <s.Column>
                  <PreviewImage src={"/images/1.jpg"} alt="image" />
                </s.Column>
                <s.Column>
                  <PreviewImage src={"/images/2.jpg"} alt="image" />
                </s.Column>
                <s.Column>
                  <PreviewImage src={"/images/3.jpg"} alt="image" />
                </s.Column>
              </s.SectionRow>
            </s.SectionColumn>
          </s.SectionRow>
        </s.SectionContainer>

        <s.SectionContainer id="fund">
          <Parallax
            bgImage="/images/bg-3.jpg"
            bgImageAlt="Background 3"
            strength={220}
          >
            <s.SectionRow>
              <s.SectionColumn>
                <s.TextTitle
                  style={{ color: "var(--primary)", textAlign: "left" }}
                >
                  BNB Growth Fund
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextP
                  style={{
                    color: "var(--primary)",
                    opacity: "0.8",
                    textAlign: "left",
                  }}
                >
                  Supporting the Binance Smart Chain and Its Ecosystem
                </s.TextP>
                <s.SpacerMedium />
                <s.TextP style={{ color: "var(--primary)", textAlign: "left" }}>
                  We are committed to supporting the Binance Smart Chain, its
                  communities, and projects, which benefits not only our
                  founders and holders but also the larger smart chain
                  ecosystem.
                  <br />
                  <br />
                  To achieve this, we will:
                  <br />
                  <br />
                  <ol style={{ listStyle: "disc", color: "var(--primary)" }}>
                    <li>Stake tokens on leading BSC projects. </li>
                    <li>Utilize Binance Smart Chain's DeFi protocols.</li>
                    <li>
                      Purchase and hold other blue-chip Binance Smart Chain NFT
                      collections.
                    </li>
                    <li>
                      Establish partnerships with other NFT collections to
                      promote the growth and adoption of the Binance Smart Chain
                      ecosystem.
                    </li>
                  </ol>
                  Our aim is to contribute to the development of the Binance
                  Smart Chain community and ecosystem, while also creating value
                  for our own community.
                </s.TextP>
              </s.SectionColumn>
              <s.SectionColumn>
                <StyledImg src={"/images/logo-maritime.png"} alt="image" />
              </s.SectionColumn>
            </s.SectionRow>
          </Parallax>
        </s.SectionContainer>

        <s.SectionContainer id="roadmap">
          <s.SectionRow>
            <s.SectionColumn>
              <s.TextTitle style={{ textAlign: "left" }}>Roadmap</s.TextTitle>

              <s.SpacerSmall />
              <s.TextP style={{ opacity: "0.8", textAlign: "left" }}>
                The majority of the funds raised will go towards the BNB Growth
                Fund, with approximately 70% being allocated to single asset
                staking pools. The rewards generated from these pools will
                primarily be used to fund NFT airdrops for our holders. These
                airdrops will be randomly distributed, except for a guaranteed
                monthly airdrop for all 1:1 holders. The NFTs can be minted or
                purchased from the secondary market, and the draws will be held
                three times per week.
                <br />
                <br />
                In addition, our Mariners Wallet provides benefits to our
                holders through raffles, competitions, and hosting the community
                treasury.
              </s.TextP>
              <s.SpacerMedium />
              <s.TextP style={{ textAlign: "left" }}>
                Here's the breakdown of the mint allocation:
                <br />
                <br />
                <ol style={{ listStyle: "disc", color: "var(--accent)" }}>
                  <li>BNB Growth Fund: 70%</li>
                  <li>Mariners Wallet: 15%</li>
                  <li>Founders and Team: 15%</li>
                </ol>
              </s.TextP>
              <s.SpacerMedium />
              <s.TextP style={{ textAlign: "left" }}>
                Our top priority is building a strong community. While we have
                many exciting ideas for the future, we are always open to
                suggestions from our community that could benefit everyone. For
                more information, please visit our Discord channel.
              </s.TextP>
              <s.SpacerMedium />
            </s.SectionColumn>
          </s.SectionRow>
        </s.SectionContainer>

        <s.SectionContainer id="team">
          <Parallax
            bgImage="/images/bg-4.jpg"
            bgImageAlt="Background 4"
            strength={220}
          >
            <s.SectionRow>
              <s.SectionColumn>
                <s.TextTitle style={{ color: "var(--primary)" }}>
                  Team
                </s.TextTitle>
              </s.SectionColumn>
            </s.SectionRow>
            <s.SectionRow
              style={{
                paddingBottom: "5%",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              <s.Column>
                <PreviewImage src={"/images/Baily.png"} alt="Bailly" />
                <s.SpacerMedium></s.SpacerMedium>
                <s.TextTitle style={{ color: "var(--primary)" }}>
                  Bailly
                </s.TextTitle>
                <s.TextP style={{ color: "var(--primary)" }}>
                  Co-Founder
                </s.TextP>
              </s.Column>
              <s.SpacerMedium></s.SpacerMedium>
              <s.Column>
                <PreviewImage
                  src={"/images/CryptoAlchemist.png"}
                  alt="CryptoAlchemist"
                />
                <s.SpacerMedium></s.SpacerMedium>
                <s.TextTitle style={{ color: "var(--primary)" }}>
                  CryptoAlchemist
                </s.TextTitle>
                <s.TextP style={{ color: "var(--primary)" }}>
                  Co-Founder
                </s.TextP>
              </s.Column>
              <s.SpacerMedium></s.SpacerMedium>
              <s.Column>
                <PreviewImage src={"/images/RetroBoy.jpeg"} alt="Retro Boy" />
                <s.SpacerMedium></s.SpacerMedium>
                <s.TextTitle style={{ color: "var(--primary)" }}>
                  Retro Boy
                </s.TextTitle>
                <s.TextP style={{ color: "var(--primary)" }}>Web3 Dev</s.TextP>
              </s.Column>
              <s.SpacerMedium></s.SpacerMedium>
            </s.SectionRow>
          </Parallax>
        </s.SectionContainer>

        <s.SecContainer id="faq">
          <s.TextTitle>Frequently Asked Questions</s.TextTitle>
          <s.SpacerLarge />
          <PanelGroup
            style={{ width: "80%", borderColor: "#A9D0D2" }}
            accordion
            bordered
          >
            <Panel header="What is the collection based on?" defaultExpanded>
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                The collection is based on how analysts compare Bitcoin network
                entities (BTC Holders) according to their holdings and divide
                them into eight separate groups, each titled after marine
                species; Shrimp, Crab, Octopus, Fish, Dolphin, Shark, Whale and
                Humpback.
              </s.TextP>
            </Panel>
            <Panel header="How many NFTs are in the collection?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                The collection includes a total of 4,960 unique NFTs, each with
                6 different traits: Species, Skin, Eyes, Mouth, Head Prop, and
                Body Prop. Additionally, the collection features 12
                one-of-a-kind 1:1 designs, including 6 Humpback species and 6
                custom mythological designs. We're excited to offer such a
                diverse and exclusive collection and look forward to the variety
                of combinations that will be minted throughout the completion of
                the minting process.
              </s.TextP>
            </Panel>
            <Panel header="What is the rarity of the NFTs in the collection?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                The collection consists of 4,960 unique NFTs, and 12
                one-of-a-kind 1:1 designs. Each NFT is minted with a unique
                combination of 6 different traits, making them all collectable
                and some combinations very rare. As the minting process
                progresses towards completion, the rarity of the NFTs will
                change.
              </s.TextP>
            </Panel>
            <Panel header="How can I mint an NFT?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                NFTs can be minted here on our website or via Rareboard.com. If
                you need assistance with the minting process, please reach out
                to us on our Discord or Telegram channel, we're happy to help.
              </s.TextP>
            </Panel>
            <Panel header="Are the NFTs tradable?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                Yes, the NFTs are currently tradable via Rareboard, NFTKey,
                Opensea, and TofuNFT.
              </s.TextP>
            </Panel>
            <Panel header="What is the BNB Growth Fund?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                The BNB Growth Fund supports the BNB Smart Chain ecosystem by
                staking tokens on leading platforms. Rewards generated are used
                to benefit the Smart Chain Maritime Society and its members
                through community initiatives, NFT airdrops and other benefits.
                The fund allows members to support the BNB Smart Chain ecosystem
                and reap its rewards.
              </s.TextP>
            </Panel>
            <Panel header="What partnerships has the project formed?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                We have partnered with Mamanime, a project that focuses on
                mental health issues and provides a sustainable financing model
                for holders. It features 1444 hand-crafted and unique art
                pieces, with 1440 algorithmically generated and 4 custom-made
                with special traits. As part of this partnership, we gifted our
                community 5 of their NFTs along with the one the Mariners wallet
                minted.
              </s.TextP>
            </Panel>
            <Panel header="What are the community initiatives?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                We have held a variety of community initiatives, including our
                Christmas Countdown event, which had 25 Days of gifts to
                holders, leading up to and including Christmas Day. Gifts
                included BNB Chain NFTs, crypto, Telegram and Discord
                subscriptions and Maritime NFT airdrops.
              </s.TextP>
            </Panel>
            <Panel header="When will the main NFT drops take place?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                The main NFT drops will take place once the minting process is
                complete. In the meantime, we have been partnering with other
                projects to give away NFTs to our holders and our Christmas
                Countdown event gave away over 4 BNB worth of prizes.
              </s.TextP>
            </Panel>
            <Panel header="How do you guarantee randomness and fairness in the NFT drops?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                The NFT drops are done using a Chainlink VRF (Verifiable Random
                Function) which ensures that the selection of the winner is
                random and unbiased. The token ID of the winner is chosen from
                the current holders and that wallet is airdropped the prize.
                This process is done in Telegram, with a link to the Chainlink
                transaction provided, and is also relayed to our Discord
                channel. This ensures a transparent and fair process for all
                holders.
              </s.TextP>
            </Panel>
            <Panel header="How can I keep up to date with development?">
              <s.TextP style={{ textAlign: "left", opacity: "0.7" }}>
                You can stay updated on the latest developments by following us
                on Twitter, Discord or Telegram.
              </s.TextP>
            </Panel>
          </PanelGroup>
        </s.SecContainer>

        <s.SectionContainer
          style={{ backgroundColor: "#F5F5F5", marginTop: "5%" }}
        >
          <s.SecContainer id="">
            <s.socialDiv>
              <a href={CONFIG.MARKETPLACE_LINK} target={"_blank"}>
                <s.Icons src="/config/images/nftkey.svg" alt="nftkey" />
              </a>
              <a href={CONFIG.Rareboard} target={"_blank"}>
                <s.Icons src="/config/images/rareboard.svg" alt="rareboard" />
              </a>
              <a href={CONFIG.Telegram} target={"_blank"}>
                <s.Icons src="/config/images/telegram.svg" alt="telegram" />
              </a>
              <a href={CONFIG.Twitter} target={"_blank"}>
                <s.Icons src="/config/images/twitter.svg" alt="twitter" />
              </a>
              <a href={CONFIG.Discord} target={"_blank"}>
                <s.Icons src="/config/images/discord.svg" alt="bscscan" />
              </a>
              <a href={CONFIG.Bscscan} target={"_blank"}>
                <s.Icons src="/config/images/bscscan.svg" alt="bscscan" />
              </a>
            </s.socialDiv>
            <s.SpacerMedium />
            <s.TextP style={{ fontSize: "0.8rem", color: "grey" }}>
              Smart Chain Maritime Society © 2022
            </s.TextP>
            <s.SpacerLarge />
          </s.SecContainer>
        </s.SectionContainer>
      </s.Container>
    </s.Screen>
  );
}

export default App;
