// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {

      let paused = await store
      .getState()
      .blockchain.smartContract.methods.paused()
      .call();

      let allowlistSale = await store
      .getState()
      .blockchain.smartContract.methods.allowlistSale()
      .call();

      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      let cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();
        cost = cost / 1000000000000000000;
        
      let allowlistSpots = await store
        .getState()
        .blockchain.smartContract.methods.allowlistSpots(account)
        .call();

      dispatch(
        fetchDataSuccess({
          paused,
          allowlistSale,
          totalSupply,
          cost,
          allowlistSpots
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
